.resultsPage {
  padding: 10px;
}

.totalTimeResultContainer {
  align-items: baseline;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-right: 50px;
}

.totalTimeTitle {
  margin-right: 10px;
}

.printButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.exportButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 10px;
}

.displayOnlyOnPrint {
  display: none;
}

@media print {
  html,
  body,
  #root,
  .App {
    height: auto !important;
  }

  .displayOnlyOnPrint {
    display: block;
  }
  .printArea {
    width: 100% !important;
    height: 100% !important;
  }
  .printArea * {
    visibility: visible;
  }
}
