.Monitor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
}

.Monitor-header {
  height: 50px;
  width: 100%;
  background-color: #222;
  color: white;
}

.Monitor-header-link {
  color: white;
  text-decoration: none;
}

.Monitor-content {
  position: relative;
  flex-grow: 1;
  overflow: visible;
  background-color: #fafafa;
}

.Monitor-task-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.Monitor-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #222;
  color: white;
}

.Monitor-footer-bloc > .Monitor-footer-button:not(:disabled) {
  color: white;
}

.Monitor-FullHeightPanel {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.Monitor-padding-left {
  padding-left: 20px;
}

.Monitor-header-centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
