html, body, #root, .App{
  height: 100%;
  font-family: "Avenir Next",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.App {
  text-align: center;
}

.Monitor-header {
  background: #2B5BA0;
}

.Monitor-footer {
  background: linear-gradient(20deg,#2B5BA0,#72C2B7);
}

@media print {
  .no-print {
    display: none !important;
  }
}