body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.Chrono.green{
  color: #1A9A58;
}

.Chrono.red{
  color: #BF0712;
}

.TaskField_input {
  box-sizing: border-box;
}

.TaskField_description {
    border: 1px solid #CACFD2;
    border-top: 0;
    border-bottom: 0;
    -ms-flex-positive: 5;
    padding: 10px;
    line-height: 20px;
    flex-grow: 5;
    min-height: 40px;
    height: 40px;
    resize: none;
    box-sizing: border-box;
    background: rgba(0,0,0,0);
}
.TaskField_estimatedTime {
  text-align: center;
  border: 1px solid #CACFD2;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  flex-grow: 1;
  width:100px;
  background: rgba(0,0,0,0);
}

.TaskField_mainContainer {
  display: inline-block;
  width: 100%;
}

.TaskField_mainContainer_top {
  display: flex;
  justify-content: space-between;
  min-height: 36px;
}

.TaskField_mainContainer_bottom {
  display: flex;
  border-top: 1px solid #CACFD2;
}

.TaskField_dragPoint {
  box-sizing: border-box;
  cursor: move;
  display: inline-block;
  height: 36px;
  padding: 0 10px;
  background: rgba(0,0,0,0);
}

.TaskEditor {
  margin: 10px 0;
}

.TaskField_check {
  flex-grow: 1;
  max-width: 500px;
  height: 24px;
  padding-left: 10px;
}

.TaskField_buttonsBloc {
  display: flex;
  justify-content: flex-end;
  width: 175px;
}

.TaskField_addCheckIcon {
  height: 20px;
  color: #228B22;
}

.TaskField_deleteIcon {
  height: 20px;
  color: #8B0000;
}

.PlanningPanel-content {
  text-align: left;
}

.PlanningPanel-tasks-textarea {
  width: 100%;
  height: 300px;
  line-height: 22px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
  margin-top: 8px;
  padding: 10px 0 5px 10px;
  font-family: "Avenir Next",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 13px;
}

.PlanningPanel-save-button-container {
  padding-top: 8px;
  text-align: right;
}

h4 {
  margin: 10px 0;
}

h5 {
  margin-bottom: 0;
}

.TaskList {
  padding-left: 20px;
}

.resultsPage {
  padding: 10px;
}

.totalTimeResultContainer {
  align-items: baseline;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-right: 50px;
}

.totalTimeTitle {
  margin-right: 10px;
}

.printButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.exportButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 10px;
}

.displayOnlyOnPrint {
  display: none;
}

@media print {
  html,
  body,
  #root,
  .App {
    height: auto !important;
  }

  .displayOnlyOnPrint {
    display: block;
  }
  .printArea {
    width: 100% !important;
    height: 100% !important;
  }
  .printArea * {
    visibility: visible;
  }
}

.TaskPanel {
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
}

.TasksLateralPanel {
  overflow: visible;
  display: inline-block;
  min-height: 100%;
  width: 100%;
  text-align: left;
  border-left: 1px solid lightgrey;
  font-family: "Avenir Next",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  background-color: #F6F6F6;
}

.TasksLateralPanel li {
  color: black;
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

.TaskRow {
  list-style-type: none;
  word-break: break-word;
  word-wrap: break-word;
  margin: 16px 10px;
  background-color: white;
  border-radius: 4px;
  padding: 10px 10px;
}

.TaskRow-bottom {
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.TaskRow.done {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.TaskRow.done .TaskRow-label {

  color: gray;
  text-decoration: line-through;
}

.TaskRow.current {
  color: black;
  font-weight: bold;
  background: lightskyblue;
  margin-left: -12px;
  margin-right: 0;
}

.TaskRow-empty {
  flex-grow: 1;
}

.TaskRow-time {
  min-width: 80px;
  font-size: 10px;
  color: grey;
  text-align: right;
}

.TaskRow-real-time {
  font-size: 16px;
  max-width: 130px;
}

.TaskRow-real-time.red {
  color: #BF0712;
}

.TaskRow-real-time.green {
  color: #1A9A58;
}

.Monitor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
}

.Monitor-header {
  height: 50px;
  width: 100%;
  background-color: #222;
  color: white;
}

.Monitor-header-link {
  color: white;
  text-decoration: none;
}

.Monitor-content {
  position: relative;
  flex-grow: 1;
  overflow: visible;
  background-color: #fafafa;
}

.Monitor-task-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.Monitor-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #222;
  color: white;
}

.Monitor-footer-bloc > .Monitor-footer-button:not(:disabled) {
  color: white;
}

.Monitor-FullHeightPanel {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.Monitor-padding-left {
  padding-left: 20px;
}

.Monitor-header-centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.Home {
  display: flex;
  height: 100%;
}

.Home-left-panel {
  padding: 12px;
  min-width: 25%;
  height: 100%;
  border-right: 1px solid black;
  background-color: white;
}

.Home-right-panel {
  flex-grow: 1;
  padding: 12px 36px;
  height: 100%;
  border-right: 1px solid black;
  overflow: scroll;
}

.select {
  min-width: 200px;
  max-width: 300px;
}

.Settings {
  text-align: left;
  height:100%;
  overflow: auto;
}

html, body, #root, .App{
  height: 100%;
  font-family: "Avenir Next",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.App {
  text-align: center;
}

.Monitor-header {
  background: #2B5BA0;
}

.Monitor-footer {
  background: linear-gradient(20deg,#2B5BA0,#72C2B7);
}

@media print {
  .no-print {
    display: none !important;
  }
}
