.TaskField_input {
  box-sizing: border-box;
}

.TaskField_description {
    border: 1px solid #CACFD2;
    border-top: 0;
    border-bottom: 0;
    -ms-flex-positive: 5;
    padding: 10px;
    line-height: 20px;
    flex-grow: 5;
    min-height: 40px;
    height: 40px;
    resize: none;
    box-sizing: border-box;
    background: rgba(0,0,0,0);
}
.TaskField_estimatedTime {
  text-align: center;
  border: 1px solid #CACFD2;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  flex-grow: 1;
  width:100px;
  background: rgba(0,0,0,0);
}

.TaskField_mainContainer {
  display: inline-block;
  width: 100%;
}

.TaskField_mainContainer_top {
  display: flex;
  justify-content: space-between;
  min-height: 36px;
}

.TaskField_mainContainer_bottom {
  display: flex;
  border-top: 1px solid #CACFD2;
}

.TaskField_dragPoint {
  box-sizing: border-box;
  cursor: move;
  display: inline-block;
  height: 36px;
  padding: 0 10px;
  background: rgba(0,0,0,0);
}

.TaskEditor {
  margin: 10px 0;
}

.TaskField_check {
  flex-grow: 1;
  max-width: 500px;
  height: 24px;
  padding-left: 10px;
}

.TaskField_buttonsBloc {
  display: flex;
  justify-content: flex-end;
  width: 175px;
}

.TaskField_addCheckIcon {
  height: 20px;
  color: #228B22;
}

.TaskField_deleteIcon {
  height: 20px;
  color: #8B0000;
}
