.PlanningPanel-content {
  text-align: left;
}

.PlanningPanel-tasks-textarea {
  width: 100%;
  height: 300px;
  line-height: 22px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
  margin-top: 8px;
  padding: 10px 0 5px 10px;
  font-family: "Avenir Next",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 13px;
}

.PlanningPanel-save-button-container {
  padding-top: 8px;
  text-align: right;
}

h4 {
  margin: 10px 0;
}

h5 {
  margin-bottom: 0;
}

.TaskList {
  padding-left: 20px;
}
