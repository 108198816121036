.Home {
  display: flex;
  height: 100%;
}

.Home-left-panel {
  padding: 12px;
  min-width: 25%;
  height: 100%;
  border-right: 1px solid black;
  background-color: white;
}

.Home-right-panel {
  flex-grow: 1;
  padding: 12px 36px;
  height: 100%;
  border-right: 1px solid black;
  overflow: scroll;
}

.select {
  min-width: 200px;
  max-width: 300px;
}
