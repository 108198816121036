.TasksLateralPanel {
  overflow: visible;
  display: inline-block;
  min-height: 100%;
  width: 100%;
  text-align: left;
  border-left: 1px solid lightgrey;
  font-family: "Avenir Next",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  background-color: #F6F6F6;
}

.TasksLateralPanel li {
  color: black;
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

.TaskRow {
  list-style-type: none;
  word-break: break-word;
  word-wrap: break-word;
  margin: 16px 10px;
  background-color: white;
  border-radius: 4px;
  padding: 10px 10px;
}

.TaskRow-bottom {
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.TaskRow.done {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.TaskRow.done .TaskRow-label {

  color: gray;
  text-decoration: line-through;
}

.TaskRow.current {
  color: black;
  font-weight: bold;
  background: lightskyblue;
  margin-left: -12px;
  margin-right: 0;
}

.TaskRow-empty {
  flex-grow: 1;
}

.TaskRow-time {
  min-width: 80px;
  font-size: 10px;
  color: grey;
  text-align: right;
}

.TaskRow-real-time {
  font-size: 16px;
  max-width: 130px;
}

.TaskRow-real-time.red {
  color: #BF0712;
}

.TaskRow-real-time.green {
  color: #1A9A58;
}
